import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, vModelText as _vModelText, withDirectives as _withDirectives, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, createCommentVNode as _createCommentVNode, withModifiers as _withModifiers } from "vue"

const _hoisted_1 = {
  class: "row justify-content-center",
  style: {"margin":"5px"}
}
const _hoisted_2 = { class: "col-md-5" }
const _hoisted_3 = { class: "form-group" }
const _hoisted_4 = {
  class: "form-group",
  style: {"margin-bottom":"10px"}
}
const _hoisted_5 = {
  class: "form-group",
  style: {"margin-bottom":"10px"}
}
const _hoisted_6 = {
  style: {"margin-bottom":"10px"},
  ref: "form"
}
const _hoisted_7 = {
  id: "carouselExampleFade",
  class: "carousel slide carousel-fade",
  "data-ride": "carousel",
  style: {"margin-bottom":"10px"}
}
const _hoisted_8 = { class: "carousel-inner" }
const _hoisted_9 = ["src"]
const _hoisted_10 = {
  key: 0,
  class: "grid grid-cols-1 grid-flow-row gap-4 md:grid-cols-2 lg:grid-cols-3"
}
const _hoisted_11 = { class: "card h-100" }
const _hoisted_12 = { class: "card-body" }
const _hoisted_13 = {
  class: "btn-group",
  role: "group",
  "aria-label": "Basic mixed styles example"
}
const _hoisted_14 = ["onClick"]
const _hoisted_15 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PhotoCard = _resolveComponent("PhotoCard")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _cache[19] || (_cache[19] = _createElementVNode("h3", { class: "text-center" }, "Редагувати категорію", -1)),
      _createElementVNode("form", {
        onSubmit: _cache[5] || (_cache[5] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.onUpdateForm && _ctx.onUpdateForm(...args)), ["prevent"]))
      }, [
        _createElementVNode("div", _hoisted_3, [
          _cache[6] || (_cache[6] = _createElementVNode("label", null, "Назва категорії", -1)),
          _createElementVNode("p", null, _toDisplayString(_ctx.photo.name), 1)
        ]),
        _createElementVNode("div", _hoisted_4, [
          _cache[7] || (_cache[7] = _createElementVNode("label", null, "Редагувати Опис", -1)),
          _withDirectives(_createElementVNode("input", {
            type: "text",
            class: "form-control",
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.photo.description) = $event)),
            required: ""
          }, null, 512), [
            [_vModelText, _ctx.photo.description]
          ])
        ]),
        _createElementVNode("div", _hoisted_5, [
          _cache[8] || (_cache[8] = _createElementVNode("label", null, "Редагувати Рік", -1)),
          _withDirectives(_createElementVNode("input", {
            type: "text",
            class: "form-control",
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.photo.year) = $event)),
            required: ""
          }, null, 512), [
            [_vModelText, _ctx.photo.year]
          ])
        ]),
        _createElementVNode("div", _hoisted_6, [
          _cache[9] || (_cache[9] = _createElementVNode("label", null, "Додати фото", -1)),
          _cache[10] || (_cache[10] = _createElementVNode("br", null, null, -1)),
          _createElementVNode("input", {
            type: "file",
            name: "file-upload",
            onChange: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.previewFiles && _ctx.previewFiles(...args))),
            multiple: "",
            accept: "image/jpeg, image/png",
            tabindex: "-1"
          }, null, 32)
        ], 512),
        _cache[14] || (_cache[14] = _createElementVNode("label", null, "Переглянути фото:", -1)),
        _cache[15] || (_cache[15] = _createElementVNode("br", null, null, -1)),
        _createElementVNode("div", _hoisted_7, [
          _createElementVNode("div", _hoisted_8, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.photo.arrayImages, (item) => {
              return (_openBlock(), _createElementBlock("div", {
                class: "carousel-item active",
                "data-interval": "1000",
                key: item.id
              }, [
                _createElementVNode("img", {
                  src: _ctx.photo.arrayImages[this.count],
                  class: "d-block w-100",
                  alt: "..."
                }, null, 8, _hoisted_9)
              ]))
            }), 128))
          ]),
          _createElementVNode("a", {
            onClick: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.clickLeft && _ctx.clickLeft(...args))),
            class: "carousel-control-prev",
            href: "#carouselExampleFade",
            role: "button",
            "data-slide": "prev"
          }, _cache[11] || (_cache[11] = [
            _createElementVNode("span", {
              class: "carousel-control-prev-icon",
              "aria-hidden": "true"
            }, null, -1),
            _createElementVNode("span", { class: "visually-hidden" }, "Previous", -1)
          ])),
          _createElementVNode("a", {
            onClick: _cache[4] || (_cache[4] = 
//@ts-ignore
(...args) => (_ctx.clickRight && _ctx.clickRight(...args))),
            class: "carousel-control-next",
            href: "#carouselExampleFade",
            role: "button"
          }, _cache[12] || (_cache[12] = [
            _createElementVNode("span", {
              class: "carousel-control-next-icon",
              "aria-hidden": "true"
            }, null, -1),
            _createElementVNode("span", { class: "visually-hidden" }, " Next ", -1)
          ]))
        ]),
        _cache[16] || (_cache[16] = _createElementVNode("label", null, "Редагувати фото:", -1)),
        _cache[17] || (_cache[17] = _createElementVNode("br", null, null, -1)),
        (_ctx.photo.arrayImages)
          ? (_openBlock(), _createElementBlock("ul", _hoisted_10, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.photo.arrayImages, (item) => {
                return (_openBlock(), _createElementBlock("li", {
                  key: item.id
                }, [
                  _createElementVNode("div", _hoisted_11, [
                    _createVNode(_component_PhotoCard, { photo: item }, null, 8, ["photo"]),
                    _createElementVNode("div", _hoisted_12, [
                      _createElementVNode("div", _hoisted_13, [
                        _createElementVNode("button", {
                          onClick: ($event: any) => (_ctx.deleteFoto(_ctx.photo, item)),
                          type: "button"
                        }, _cache[13] || (_cache[13] = [
                          _createElementVNode("i", {
                            style: {"color":"darkred","margin-right":"10px"},
                            class: "fas fa-trash-alt fa-2x"
                          }, null, -1)
                        ]), 8, _hoisted_14),
                        _createElementVNode("button", {
                          onClick: ($event: any) => (_ctx.FirstFoto(_ctx.photo, item)),
                          type: "button",
                          class: "btn btn-warning btn-sm"
                        }, " Головна ", 8, _hoisted_15)
                      ])
                    ])
                  ])
                ]))
              }), 128))
            ]))
          : _createCommentVNode("", true),
        _cache[18] || (_cache[18] = _createElementVNode("div", { class: "form-group" }, [
          _createElementVNode("button", { class: "btn btn-primary btn-block" }, " Зберегти ")
        ], -1))
      ], 32)
    ])
  ]))
}